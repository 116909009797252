<template>
  <div class="home">
    <index-slider />
    <catalog v-if="getCars.length" />
    <!--    <index-reviews />-->
    <quetions id="questions" />
  </div>
</template>

<script>
// @ is an alias to /src
import Catalog from "@/components/components/Catalog/Catalog";
import IndexSlider from "@/components/index/IndexSlider";
import Quetions from "@/components/index/Quetions";
import { mapGetters } from "vuex";
// import IndexReviews from "@/components/index/IndexReviews";

export default {
  name: "Home",
  head: {
    title: function () {
      return {
        inner: this.$route.meta?.title || "Прокат авто",
      };
    },
  },
  mounted() {
    if (this.$route.hash) {
      this.$scrollTo(this.$route.hash);
    }
  },
  computed: {
    ...mapGetters("car", ["getCars"]),
  },
  components: {
    // IndexReviews,
    Quetions,
    IndexSlider,
    Catalog,
  },
};
</script>
